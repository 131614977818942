import React from 'react'
import { Box } from '@chakra-ui/react'
import Layout from '../components/Layout'

const GetInvolved = () => {
  return (
    <Layout>
      <Box>
        This is get involved page
      </Box>
    </Layout>
  )
}

GetInvolved.Layout = Layout
export default GetInvolved
